import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

export default class Page extends React.Component {
  render() {
    return (
      <Layout container={false} isDark={true} hasDarkColorScheme={true}>
        <div className="odd">
        <SEO title="Imagefilm Produktion für Instagram, Facebook und Youtube Spots"></SEO>

            <div className="container-fluid hero-full hero-big has-bg-img">

                <picture>
                    <source srcSet={require("../../images/services/film/hero.jpg")} type="image/jpeg"/>
                    <source srcSet={require("../../images/services/film/hero.jpg.webp")} type="image/webp"/>
                    <img src={require("../../images/services/film/hero.jpg")}/>
                </picture>

                <div className="text-center">
                    <h1 className="text-white">Begeistern. Und das 25 mal pro Sekunde.</h1>
                    <p className="hidden-sm  text-white mt-2"> Bitte nach unten scrollen.</p>
                </div>
            </div>

            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/konzeption.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/konzeption.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/konzeption.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Konzeption</h1>
                        <p className="text-gray">Wir setzen uns für Sie zu sogenannten Creative Sessions zusammen und entwerfen ein Treatment, um Ihre Message zu transportieren. </p>
                </div>
            </div>



            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/planung.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/planung.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/planung.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Planung</h1>
                        <p className="text-gray">Bevor wir die Kamera anfassen, steht der gesamte Film bereits in unseren Köpfen.
       Wir fertigen für Sie ein Animatic an, so können Sie Ihren Film bereits vor dem Dreh in animierter Form sehen.</p>
                </div>
            </div>



            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/dreharbeiten.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/dreharbeiten.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/dreharbeiten.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Dreharbeiten</h1>
                        <p className="text-gray">Wir gehen mit Ihnen die letzten Details durch und bereiten die Technik vor. 
       Der eigentliche Dreh ist fast ausschließlich eine logistische Leistung, die auf guter Disposition aufbaut.</p>
                </div>
            </div>



            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/studio.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/studio.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/studio.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Studio</h1>
                        <p className="text-gray">
Wir bauen ein Set genau nach Ihren Anforderungen und verfügen über einen Greenscreen,
        sodass wir in der Postproduktion den Hintergrund einer Person beliebig wählen können. </p>
                </div>
            </div>


            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/aussendreh.jpg")} type="image/jpeg"/>
                            <img src={require("../../images/services/film/aussendreh.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Außendreh</h1>
                        <p className="text-gray">Das beste am Film ist, 
        neue Dinge kennen zu lernen. 
        Wir begleiten Sie bei Ihrem Aussendreh. Egal ob Land, Wasser oder in der Luft.</p>
                </div>
            </div>


            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/setdesign.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/setdesign.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/setdesign.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Set Design</h1>
                        <p className="text-gray">
        "Ein Film steht und fällt mit seinem Set Design." - So oft man diesen Satz auch vielleicht schon gehört hat,
      ein gutes Set Design kann für einen hohen Production Value ausschlaggebend sein. 
        </p>
                </div>
            </div>



            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/licht.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/licht.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/licht.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Professionelle Lichttechnik</h1>
                        <p className="text-gray">
        Wir setzen auf branchenführende Lichtfirmen wie Dedolight und ARRI,
        um tageszeitunabhängig jede Lichtstimmung erzeugen zu können.
        </p> 
                </div>
            </div>





            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/postproduktion.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/postproduktion.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/postproduktion.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Postproduktion</h1>
                        <p className="text-gray">Nachdem alles abgedreht ist beginnt die eigentliche Entstehungsphase.
        Editor und Regie setzen sich zusammen und erstellen einen Rohschnitt.  </p>
                </div>
            </div>




            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/schnitt.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/schnitt.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/schnitt.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Schnittprozess</h1>
                        <p className="text-gray">Wir wählen für unseren Schnitt einen Branchenstandard, der durch die Vereinigung von 
         Schnitt, Colorgrading, visuellen Effekte, Motion Graphics und Audio Post Produktion einen besonders effektiven und direkten Workflow im Team ermöglicht.</p>
                </div>
            </div>




            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/sprecherin.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/sprecherin.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/sprecherin.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Studio Sprecher*in</h1>
                        <p className="text-gray">Wählen Sie aus über 20 unterschiedlichen Stimmen aus. Professionell eingesprochene Voiceovers wirken einladend auf Ihr Publikum. </p>
                </div>
            </div>




            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/grading.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/grading.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/grading.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Koloration</h1>
                        <p className="text-gray">Für Kinofilme und Fernsehsendungen benutzen Fachleute aus dem Topsegment DaVinci Resolve häufiger als jede andere Lösung. 
        Sie werden nach der Koloration einen ganz neuen Film sehen.</p>
                </div>
            </div>




            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/x32.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/x32.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/x32.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Sounddesign & Vertonung</h1>
                        <p className="text-gray">Der Fairlight-Arbeitsraum ist mit Hunderten professionellen Tools für die Audiopostproduktion ausgestattet.
        So können wir Ihren Film zum Beispiel in 5.1 Surround abmischen. </p>
                </div>
            </div>


            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/dcp.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/dcp.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/dcp.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Ausspielung in Industrie Standard</h1>
                        <p className="text-gray">Sie können den fertigen Film in allen gängigen Formate erhalten, unter andem Quicktime und Digital Cinema Package (Kino Standard).</p>
                </div>
            </div>


            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/catering.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/catering.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/catering.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Und das alles Grün</h1>
                        <p className="text-gray">Die Film Industrie kann sehr Umweltbelastend sein, doch wir wirken entgegen, wo wir können. </p>
                        <Link to="/gogreen#film" class="text-lime">Mehr erfahren ›</Link>
                </div>
            </div>



            <div className="container-fluid hero container-col-2">
                    <div className="img has-bg-img">
                        <picture>
                            <source srcSet={require("../../images/services/film/start.jpg")} type="image/jpeg"/>
                            <source srcSet={require("../../images/services/film/start.jpg.webp")} type="image/webp"/>
                            <img src={require("../../images/services/film/start.jpg")}/>
                        </picture>
                    </div>
                    <div className="textbox">
                        <h1>Jetzt starten</h1>
                        <p className="text-gray">Wir sind gespannt, aus Ihrer Geschichte ein Drehbuch zu entwickeln! </p>
                        <Link to="/spec-spots" class="text-lime">Beispiele ansehen ›</Link>
                        <br/>
                        <Link to="/mehr-erfahren" style={{marginTop:5,display:"inline-block"}} class="text-lime">Jetzt anfragen ›</Link>
                </div>
            </div>









        </div>

            <div className="container-fluid hero" id="coffee">
                <div className="container">

                        <picture>
                            <source srcSet={require("../../images/landing/coffee_1500.jpg.webp")} type="image/webp"/>
                            <source srcSet={require("../../images/landing/coffee_1500.jpg")} type="image/jpeg"/>
                            <img loading="lazy" src={require("../../images/landing/coffee_1500.jpg")}/>
                        </picture>


                    <div className="textbox">
                        <h1 className="text-white">Auf n Kaffee?</h1>
                        <p className="text-white">Gerne erzählen wir Ihnen mehr!  </p>
                        <Link to="/mehr-erfahren" className="text-white btn btn-lime-outline">Jetzt anfragen ›</Link>
                    </div>
                </div>
            </div>



     
      </Layout >
    )
  }
}



